// import PageIndex from "./index";
import { h } from "preact";

import { useRouter } from "next/router";

// TODO: custom 404 page
const MissingPage = () => {
  const router = useRouter();
  if (process.browser) {
    router.push("/");
  }

  return <div>Loading...</div>;
};

// TODO:
export default MissingPage;
